import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import gdnNetworkM from '../../../../assets/newAssets/guardians/guardian-network-group-m.webp';
import gdnNetwork from '../../../../assets/newAssets/guardians/guardian-network-group.webp';
import gdnNetworkMEs from '../../../../assets/newAssets/guardians/guardian-network-group-m-es.webp';
import gdnNetworkEs from '../../../../assets/newAssets/guardians/guardian-network-group-es.webp';

import { GuardianNetworkSection } from './Styles';

const GuardianNetwork = ({ lang }) => {
    console.log('lang', lang);
    return (
        <GuardianNetworkSection className="text-center">
            <picture>
                <source
                    media="(max-width:700px)"
                    srcset={lang === 'ES' ? gdnNetworkMEs : gdnNetworkM}
                />
                <img
                    src={lang === 'ES' ? gdnNetworkEs : gdnNetwork}
                    className="w-full"
                    alt="Guardian network group"
                />
            </picture>
            <h3 className="h2">
                <FormattedMessage
                    id="GuardiansPage.Network.Join"
                    values={{
                        br: <br />,
                    }}
                />
            </h3>
            <Link to="/landscapes" className="green-link">
                <FormattedMessage id="GuardiansPage.Network.WorkLink" />
            </Link>
        </GuardianNetworkSection>
    );
};

export default GuardianNetwork;
